<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">

        <a-col :sm="24" :md="12" :lg="8" :xl="8">
          <a-form-model-item label="备注" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="remark">
            <a-input v-model="form.remark" placeholder="请输入备注..." />
          </a-form-model-item>
        </a-col>
  
       

        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">

            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>

          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>
    <div class="mainContent" style="display: flex;justify-content: flex-end;">
      <a-button type="primary" @click="onAdd">
        添加
      </a-button>
    </div>

    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.id"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >
      
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

              <span >
                <a-button size="small" type="primary"  @click="onEdit(data)">编辑</a-button>
                <a-popconfirm title="是否删除此信息?" @confirm="() => onDelete(data)">
                  <a-button size="small" type="danger">删除</a-button type="primary" >
                </a-popconfirm>
              </span>

        </template>
      </a-table>
    </div>


    <!-- 修改帐号信息模态框 -->
    <a-modal
      :maskClosable="false"
      :title="title"
      :visible="editModalVisible"
      :confirm-loading="confirmLoading"
      @ok="saveCards"
      @cancel="cancelCards"
    >
      <!-- 编辑 -->
      <a-form-model
        ref="cards"
        :model="cardsForm"
        :rules="cardsFormRules"
        :label-col="{span:7}"
        :wrapper-col="{span:17}"
      >

      <a-form-model-item label="卡号" prop="card_content">
        <a-textarea :auto-size="{ minRows: 6,}" v-model="cardsForm.card_content" placeholder="请输入卡号"/>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="cardsForm.remark" placeholder="请输入备注"/>
      </a-form-model-item>
      <a-form-model-item label="客服" prop="user_id">
        <a-select v-model="cardsForm.user_id" placeholder="请选择客服..." :options='userList'>

        </a-select>
      </a-form-model-item>

      </a-form-model>
    </a-modal>



  </div>
</template>
<script>
import {getCardsList,addCards,deleteCards,editCards} from "@/axios/cards.js"
import {getAllUser} from "@/axios/user.js"
import {getCookie} from '@/static/js/cookie';
import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';
const columns = [//列描述
  {
    align:'center',
    title: 'ID',
    dataIndex: 'id',
  },
  {
    align:'center',
    title: '卡号备注',
    dataIndex: 'remark',
  },
  {
    align:'center',
    title: '客服',
    dataIndex: 'username',
  },

  {
    align:'center',
    title: '创建时间',
    dataIndex: 'create_time',
  },
  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {
  filters: {

    // roleFilter(status) {
    //   const statusMap = {
    //     'super': '超级管理员',
    //     'normal': '供应商',
    //     'agent':'代理'
    //   }
    //   return statusMap[status]
    // },
  },
  mixins: [myMixin],
  data() {

    return {


      form: {
        page_size: 10,
        page:1,
        remark:'',

      },

      columns,//列描述

      confirmLoading:false,//编辑模态框
      editModalVisible:false,
      cardsForm:{
        id:'',
        remark:'',
        card_content:'',
        user_id:'',
      },
      cardsFormRules: {
        
        card_content: [
          { required: true, message: '请输入卡号', trigger: 'blur' },
        ],
        remark: [
          { required: true, message: '请输入备注', trigger: 'blur' },
        ],
        user_id: [
          { required: true, message: '请选择客服', trigger: 'change' },
        ],

      },

      title:'添加卡号',
      userList:[],
    }

  },
  mounted() {
    let that=this
    getAllUser({show_super:1}).then((data)=>{
      this.userList=data.data.map((item)=>{
          return{
            value:item.id,
            label:item.username
          }
        })
      });
    this.fetch();
  },
  methods:{


      async fetch (params = {}) {
        this.loading = true;

        let data=await getCardsList({...params,...this.form})
        const pagination = { ...this.pagination };
          pagination.total = data.data.total;

          this.data = data.data.list;
          this.pagination = pagination;
          console.log(data,this.pagination,pagination,'data')
    
        this.loading = false;
        
      },

      //添加商户数据
      onAdd(){
        this.editModalVisible=true
        this.title='添加卡号'
      },

      //删除数据
      async onDelete(data){

        let resule=await deleteCards({id:data.id});
          if(resule.code==200){
            this.$message.success(resule.msg);
            this.fetch()
          }else{
            this.$message.error(resule.msg);
          }
      },

      //编辑保存账户数据
      onEdit(data){
        this.title='编辑卡号'
        this.editModalVisible=true
        this.$nextTick(() => { // 不用nextTick的话，清空数据时会有问题
          for (let key in this.cardsForm) {
            this.cardsForm[key] = data[key];
          }
          console.log(this.cardsForm,this.cardsForm.bw_switch,data.bw_switch,'点编辑时的信息')
        });


      },
      
      //更新支付方式
      saveCards(){
        this.$refs.cards.validate( async valid => {
        if (valid) {
          this.confirmLoading = true;
          let resule
          if(this.title=='添加卡号'){
            resule=await addCards(this.cardsForm);
          }else{
            resule=await editCards({config_id:this.cardsForm.id,...this.cardsForm,});
          }
          

          if(resule.code==200){
            this.$message.success(resule.msg);
            this.editModalVisible = false;
            this.$refs.cards.resetFields();
            this.fetch();
          }else{
            this.$message.error(resule.msg);
          }
          this.confirmLoading = false;
        } else {
          return false;
        }
      });

      },

      cancelCards(){
        this.$refs.cards.resetFields();
        console.log(this.cardsForm,'cardsForm')
        this.editModalVisible = false;
      },


     
    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }

::v-deep .ant-table-bordered .ant-table-thead > tr > th{    word-break: inherit;}
::v-deep .ant-modal-body {

    max-height: 600px;
    overflow-x: auto;
}
</style>
